.plainLink {
  width: 100%;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

.inline {
  display: inline;

  p {
    display: inline;
  }
}
